<template>
  <footer>
    <div class="content" v-if="data.siteParams.siteCompanyName">
      <div class="flex-row justify-between">

        <div class="flex-row">
          <div class="flex-column">
            <div class="btm-left-logo flex-row align-center">
              <div class="logoBox">
                <img src="../../../public/logo.png">
              </div>
              <div class="textBox">
                <p>{{ data.siteParams.siteCompanyName.value }}</p>
                <p>{{ data.siteParams.siteCompanyName.shortName }}</p>
              </div>
            </div>
            <div class="btm-left-info flex-row">
              <ul>
                <li v-if="data.shopList" class="flex-row">
                  <p>体验地址：</p>
                  <div>
                    <p class="text-ellipsis" style="width: 100%" v-for="(item,index) in data.shopList" :key="index">{{item.address}}</p>
                  </div>
                </li>
                <li v-if="data.siteParams.companyAddress">科研地址：{{ data.siteParams.companyAddress.value }}</li>
                <!--            <li v-if="data.siteParams.siteCompanyEmail">邮箱：{{ data.siteParams.siteCompanyEmail.value }}</li>-->
                <li v-if="data.siteParams.siteCompanyTel">咨询电话：{{ data.siteParams.siteCompanyTel.value }}</li>
              </ul>
            </div>
          </div>

          <div style="margin-left: 30px">
            <ul class="articleBox" v-if="data.NavigatorList" v-for="(item,index) in data.NavigatorList" :key="index">
              <li>
                <div>{{ item.name }}</div>
                <div class="articleItem pointer" v-for="(items,i) in item.liset" :key="i" @click="toDetails(items.articleID)">
                  <p class="text-ellipsis">
                    {{ items.title }}
                  </p>
                </div>
              </li>
            </ul>
          </div>

        </div>

        <div class="flex-row">
          <div class="flex-column align-center" style="margin-right: 30px">
            <div class="imgCode">
              <img v-if="data.siteParams.weixinLoginQcodePage" :src="data.siteParams.weixinLoginQcodePage.value" alt="">
            </div>
            <div>微信小程序</div>
          </div>
          <div class="flex-column align-center">
            <div class="imgCode">
              <img v-if="data.siteParams.siteWeixinQrcodeUrl" :src="data.siteParams.siteWeixinQrcodeUrl.value" alt="">
            </div>
            <div>微信公众号</div>
          </div>
        </div>
      </div>

      <div>
        <a target="_blank" rel="nofollow" href="https://beian.miit.gov.cn/" class="flex-row flex-center">
          <img src="https://static.shejidedao.com/pc/img/guohui.png">
          <p v-if="data.siteParams.webSiteCertificate" style="color: #FFFFFF">
            {{ data.siteParams.webSiteCertificate.value }}</p>
        </a>
      </div>

    </div>
  </footer>
</template>
<script setup>
import {useStore} from 'vuex';
import {useRouter} from "vue-router"
import {ElMessage} from "element-plus";
import {inject,ref, reactive, onMounted} from "vue";
import {getShopList} from '@/common/api'

let Common = inject('Common');

const {state} = useStore();
const router = useRouter();

const data = reactive({
  siteParams: {},
  NavigatorList: [],
  shopList: []
});
//const siteParams = ref({})

//data.siteParams = JSON.parse(Common.getStorage('siteParams'))
//data.NavigatorList = state.nav.footerList
//console.log(data.siteParams, 'siteParams')
//console.log(data.NavigatorList, 'NavigatorList')

// const toPath = (ite) => {
//     if(!ite.articleID)return
//     if(ite.title.length>20) {
//         ElMessage({
//             message: "无内容",
//             type: "warning",
//         });
//         return
//     }
//     router.push(`/articleDetails?articleID=${ite.articleID}`)
// }
const getShop = () => {
  getShopList({
    shopType: 0,
    sortTypeOrder: 1
  }, res => {
    //console.log(res, '实体店铺')
    let resData= res.data.body.data.rows
    data.shopList=resData.slice(0,3)
  })
}
//底部导航栏文章详情跳转
const toDetails=(id)=>{
  router.push({path:'/articleDetails',query:{id}})
}
onMounted(()=>{
  data.siteParams = JSON.parse(Common.getStorage('siteParams'))
  //console.log(data,'data')
  data.NavigatorList = state.nav.footerList
  getShop();
})
</script>
<style lang="scss" scoped>
footer {
  width: 100%;
  //height: 400px;
  //background: #1F2A2C;
  background: var(--mainColor);
}

.content {
  color: #FFFFFF;
  padding: 50px 5% 20px 5%;


  .btm-left-logo {
    width: 330px;
    margin-bottom: 20px;

    .logoBox {
      width: 80px;
      height: 80px;
      margin-right: 20px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .textBox {
      width: 230px;
    }
  }

  .btm-left-info {
    ul {
      width: 330px;
      li{
        width: 100%;
        >p{
          width: 90px;
        }
        >div{
          width: calc(100% - 90px);
        }
      }
    }
  }

  .articleBox {
    width: 200px;
    margin-right: 30px;

    .articleItem {
      width: 100%;
    }
  }

  .articleBox:last-child {
    margin-right: 0;
  }


  .imgCode {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>