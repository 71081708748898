<template>
  <header>
    <div class="header" v-if="data.siteParams">
      <!-- <h3>BITMAMO</h3> -->
      <div class="flex-row align-center">
        <div class="header_logo">
          <img src="../../../public/logo.png" alt="">
        </div>
        <p style="margin-left: 20px;font-weight: bold;font-size: 20px" v-if="data.siteParams.siteCompanyName">
          {{ data.siteParams.siteCompanyName.value }}</p>
      </div>
      <ul ref="navRef">
        <li
            v-for="(item, index) in data.headerList"
            @click="bthClick(item)"
            :key="index"
            @mouseenter="showDropDown(index)"
        >
          <p>{{ item.name }}</p>
        </li>
      </ul>
      <div class="drop-box flex-row justify-center align-center" v-show="data.diaShow" @mouseleave="hideDropDown">
        <div class="drop-li pointer" v-for="(sonItem,index) in data.diaList" :key="sonItem.categoryID"
             @click="href(index)">
          <img :src="sonItem.largeIcon || sonItem.smallIcon">
          <p>{{ sonItem.name }}</p>
        </div>
        <div class="drop-li pointer" @click="href(0)">
          <img src="@/assets/img/arrRight.png">
          <p>更多</p>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {inject, reactive, ref, onMounted, getCurrentInstance} from "vue";
import Common from "@/common/common.js";
import {
  getCartGoodsTotal,
} from "@/common/api.js";
//let login = inject("login");
const store = useStore();
const router = useRouter();
const currentInstance = getCurrentInstance()
//let clickArr = ["我的账户", "购物车"];
const data = reactive({
  language: Common.language,
  siteParams: {},
  headerList: [],
  diaList: [],
  diaShow: false,
  navTop: '',
  navCurrent:0,
});
//data.siteParams = JSON.parse(Common.getStorage('siteParams'))
//data.headerList = store?.state?.nav?.headerList
const bthClick = (item) => {
  //   console.log(Common.language)
  // console.log(item);
  router.push(item.functionURL);
  // if (item.navigatorID == "0b505ea95b104c59830bc70ea64cc11f") {
  //   Common.language = !Common.language;
  //   data.language = !data.language;
  //   store.commit('languageState', data.language)
  // } else {
  //   router.push(item.functionURL);
  // }
};

const showDropDown = (idx) => {
  if (!data.headerList[idx].sonList) return data.diaShow = false;
  if (!data.diaShow) data.diaShow = true;
  data.navCurrent = idx
  data.diaList = data.headerList[idx].sonList
}

const hideDropDown = (e) => {
  // e.pageY：鼠标指针相对页面的偏移量
  if (data.diaShow && e.pageY >= data.navTop) data.diaShow = false;
}

const href = (idx) => {
  console.log(data.navCurrent,'navCurrent')
  let url = ''
  if (data.navCurrent == 1){
    url = `/product?idx=${idx}`
  }else if (data.navCurrent == 2){
    url = `/scheme?idx=${idx}`
  }
  router.push(url)
}

onMounted(() => {
  data.siteParams = JSON.parse(Common.getStorage('siteParams'))
  data.headerList = store?.state?.nav?.headerList
  data.navTop = currentInstance.refs.navRef.getBoundingClientRect().top + currentInstance.refs.navRef.offsetHeight;
})
</script>
<style lang="scss" scoped>
header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 666;
  border-bottom: 2px solid #EFEFEF;
  background: #fff;
}

.header {
  //width: 1366px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h3 {
    height: 28px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
    padding-left: 60px;
  }

  > ul {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    > li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      > p {
        font-size: 18px;
        font-weight: bold;
        color: #545151;
        line-height: 23px;
        cursor: pointer;
      }


    }

    li:last-child {
      margin-right: 0;
    }
  }

  .drop-box {
    position: fixed;
    top: 80px;
    left: 0;
    background-color: #ffffff;
    width: 100vw;
    padding: 30px 0;

    .drop-li {
      width: 150px;
      margin-right: 30px;

      > img {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }

      > p {
        font-size: 14px;
        color: #A2A2A2;
        width: 100%;
        text-align: center;
        margin-top: 5px;
      }
    }

    .drop-li:last-child {
      margin-right: 0;
    }
  }

  .header_logo {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>