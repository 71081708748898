import {
    getNavigatorList,
    getFocusList,
    getNavigatorSimpleArticleList,
    getNavigatorArticleList,
    getCategoryList, getNSNavigatorList
} from "../../common/api"
import {ElMessage} from "element-plus";
import common from "@/common/common";

function AD() {
    return new Promise((resolve, reject) => {
        getNavigatorList({
            siteID: common.siteID,
            navigatorID: common.navIdList.headerId,
            sortTypeOrder: 1
        }, res => {
            resolve(res)
        })
    })
}

export default {
    namespaced: true,
    state() {
        return {
            // 导航信息
            navList: [],
            homeNavList: [],
            headerList: [],
            footerList: [],
            homeSwiper: [],
        }
    },
    mutations: {
        navId(state, data) {
            state.navList = data
        },
        homeNavID(state, data) {
            state.homeNavList = data
        },
        footerId(state, data) {
            state.footerList = {...data}
        },
        headerId(state, data) {
            state.headerList = {...data}
        },
        homeId(state, data) {
            state.homeSwiper = data
        },
    },
    //这里定义异步操作和提交mutations的方法
    actions: {
        //获取导航列表
        getNavList(context) {
            for (let key in common.navIdList) {
                getNavigatorList({
                    siteID: common.siteID,
                    navigatorID: common.navIdList[key],
                    sortTypeOrder: 1
                }, res => {
                    let data = []
                    if (res.data.header.code === 0) {
                        data = res.data.body.data.rows
                        if (key !== 'footerId') {
                            context.commit(key, data)
                            //console.log('111',data);
                            return
                        }
                        data.forEach(item => {
                            getNavigatorSimpleArticleList({
                                navigatorID: item.navigatorID,
                                sortTypeOrder: 1
                            }, res => {
                                item.rows = res.data.body.data.rows
                                item.rows.forEach((it) => {
                                    if (it.name.length > 20) {
                                        it.name = common.getNewline(it.name, 20)
                                        it.shortName = common.getNewline(it.shortName, 20)
                                        console.log(common.getNewline(it.name, 20));
                                    }
                                })
                                context.commit(key, data)
                                //console.log('222333',data);
                            })
                        });
                    } else {
                        ElMessage({
                            message: '请求失败',
                            type: 'error',
                        })
                    }
                })
            }
        },
        //获取首页一级导航
        getHomeNavigatorList(context) {
            getNavigatorList({
                navigatorID: common.navIdList.homeID,
                pageNumber: 999,
                sortTypeOrder: 1
            }, res => {
                let data = []
                if (res.data.header.code === 0) {
                    data = res.data.body.data.rows
                    context.commit('homeNavID', data)
                } else {
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })
        },
        //获取swiper
        getSwiper(context) {
            for (let key in common.swiper) {
                getFocusList({
                    siteID: common.siteID,
                    focusID: common.swiper[key],
                    sortTypeOrder: 1
                }, res => {
                    if (res.data.header.code === 0) {
                        context.commit(key, res.data.body.data.rows)
                    } else {
                        ElMessage({
                            message: '请求失败',
                            type: 'error',
                        })
                    }
                })
            }
        },
        //获取顶部导航列表
        getNavList2(context, shortName) {
            getNavigatorList({
                siteID: common.siteID,
                navigatorID: common.navIdList.headerId,
                sortTypeOrder: 1
            }, async res => {
                if (res.data.header.code === 0) {
                    let data = res.data.body.data.rows
                    await Promise.all(
                        data.map((item) => {
                            if (item.name == '产品介绍') {
                                return new Promise(resolve => {
                                    getCategoryList({
                                        categoryID: common.goodsCategoryID,
                                        depth: 1,
                                    }, newData => {
                                        item.sonList = newData.data.body.data.rows.slice(0, 4) || []
                                        resolve(item.sonList)
                                    })
                                })
                            }
                            if (item.name == '方案介绍') {
                                return new Promise(resolve => {
                                    getNSNavigatorList({
                                        navigatorID: common.schemeNavID,
                                        sortTypeOrder: 1
                                    }, newData => {
                                        item.sonList = newData.data.body.data.rows.slice(0, 4) || []
                                        resolve(item.sonList)
                                    })
                                })
                            }
                        })).then(allRes => {

                        context.commit('headerId', data)
                    })
                } else {
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })

        },
        //获取底部导航列表
        getNavList3(context,) {
            getNavigatorList({
                siteID: common.siteID,
                navigatorID: common.navIdList.footerId,
                sortTypeOrder: 1
            }, res => {
                let data = []
                if (res.data.header.code === 0) {
                    data = res.data.body.data.rows
                    data.forEach((item) => {
                        item.liset = []
                        getNavigatorArticleList({
                            //siteID: common.siteID,
                            navigatorID: item.navigatorID,
                            sortTypeOrder: 1
                        }, res => {
                            if (res.data.header.code == 0) {
                                let ArticleList = res.data.body.data.rows
                                item.liset = ArticleList
                            }
                        })
                    })
                    setTimeout(() => {
                        //console.log('footerId',data);
                        context.commit('footerId', data)
                    }, 1000)
                } else {
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })
        },
    }
}