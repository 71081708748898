import {createRouter, createWebHashHistory} from 'vue-router'
//import Home from '../views/huoxingViews/homePage/homePage.vue'
//import login from '../components/login/index'
//import routers from './huoxiangViews/index.js'
const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/brainPCView/home.vue')
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('@/views/brainPCView/product.vue')
    },
    {
        path: '/scheme',
        name: 'scheme',
        component: () => import('@/views/brainPCView/scheme.vue')
    },
    {
        path: '/case',
        name: 'case',
        component: () => import('@/views/brainPCView/case.vue')
    },
    {
        path: '/information',
        name: 'information',
        component: () => import('@/views/brainPCView/information.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/brainPCView/about.vue')
    },
    {
        path: '/articleDetails',
        name: 'articleDetails',
        component: () => import('@/views/brainPCView/articleDetails.vue')
    },
    {
        path: '/productDetails',
        name: 'productDetails',
        component: () => import('@/views/brainPCView/productDetails.vue')
    },
    {
        path: '/jobInfo',
        name: 'jobInfo',
        component: () => import('@/views/brainPCView/jobInfo.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/loginPage.vue')
    },
    // ...routers
]

const router = createRouter({
    //createWebHashHistory
    // createWebHistory
    history: createWebHashHistory('/'),
    routes
})
// router.beforeEach((to, from, next) => {
// 	if (!localStorage.getItem('sessionID')) {
// 		if (to.name == "home"||to.name == "articleDetails") {
// 			next();
// 		} else {
//       login(to.fullPath)
// 		}
// 	} else {
// 		next();
// 	}
// });
export default router
