function phoneInput(e) {
    console.log(e)
}
export default {
    errorToBack: errorToBack,
    getStorage: getStorage,
    removeStorage: removeStorage,
    phoneInput: phoneInput,
    isWeiXinBrowser:isWeiXinBrowser,
    jumpToLoginWeiXin:jumpToLoginWeiXin,
    titleID: [{ name: "先生", titleID: '1d88af067fff4d6e95575e77f7365029' }, { name: "女士", titleID: '384aba23a5ac4344b5848250d793cd64' }],
    //站点ID
    siteID: '8a2f462a8a4f4101018a5d335af10534', //脑波pc
    //公司ID
    companyID: '8a2f462a8a4f4101018a5d2ff46904d1', //上海觉而思生命科技有限公司
    //应用ID
    applicationID:'8a2f462a8a4f4101018a5d33531c04d7',
    //导航ID
    navIdList: {
        // navId: '09048fa4d8cb4537b510a377f86f2932',
        homeID:'2936d9b7ed0c4504a5bb4db51a06d8f7',//首页导航id
        footerId: '6576fa1be9b54ff4a728b6b15f0fabfc',//底部导航
        headerId: '5d8b820b4e27413da161b6b5720fe441',//顶部导航id
        RecommendedNavigationId:'86bd3aff9e0f4d49b4eefd4f58d4c2f9'//首页推荐商品导航id
    },
    aboutArticleID:'8a2f462a8a4f4101018a5d3353be04d9',//关于我们系统文章id
    memberRecommendID:'3e014e728095475d91c4090c47eb0550',//会员推荐导航id
    tacticStoryRecommendID:'47bd2bd0de22443d9870aac6212caf5a',//战略合作伙伴导航id
    //广告id
    ADStoryRecommendID:'1e56d86b68ab451f956b5303468fdbe3',//合作伙伴导航ID
    //ImgAdID:'ff8080818c85c3b4018c8672c9d20090',
    textAdID:'ff8080818c85c3b4018c868487a50091',
    withdrawDefineID:'8a2f462a8a4f4101018a5d3353ff04df',
    goodsCategoryID:'58b12a5b1d1043a2997213659f7ea89e',//商品分类id
    // 支付ID
    paywayID:'8a2f462a89aecd860189bfaa80120f47',
    //轮播图ID
    swiper:{
        homeId:'cd0843fcbb8c43818b9bc7b228fb5581'
    },
    //语言
    language: true,
    // deviceID: '0268fffba23f48bf877f445e0536e45a',//好聚
    // deviceID: '2c9fa8bb89f9bb900189fd7f25020134',//火星
    deviceID: '6556e0eba5b74699a1731512bba715b4',//脑波
    schemeNavID:'ff96385bc01a410694156331b5afc770',
    
    // defaultAvatarURL:'',
    periodType:[{id:'1',name:'日',shortName:'day'},{id:'2',name:'月',shortName:'month'},{id:'3',name:'年',shortName:'year'},{id:'10',name:'次',shortName:'times'}],
    bannerHeight:0,
}
// 获取localStorage
function getStorage(name) {
    if (!name) return
    return window.localStorage.getItem(name)
}

// 删除localStorage
function removeStorage(name) {
    if (!name) return
    window.localStorage.removeItem(name)
}
// 当出错的时候，显示错误信息，并且跳转
function errorToBack(msg = '出错了，请重试', time = 1500) {
    console.log(msg)
    // _this.$dialog.toast({
    //     mes: msg,
    //     timeout: time
    // })
}
function isWeiXinBrowser() {
    // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase()
    // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
    var uas = ua.match(/MicroMessenger/i)
    if (uas) {
        if (ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}
function jumpToLoginWeiXin() {
    let that = _this;
    removeStorage('sessionID');
    removeStorage('memberID');
    that.code = ''
    var local = location.origin + '/authNew';
    var appid = 'wxa41d50db32b07abc';
    that.code = getUrlCode().code // 截取code
    if (that.code == null || that.code === '') { // 如果没有code，则去请求
        setStorage('beforeUrl', route.fullPath)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
    } else {
        // 有code
    }
}