<template>
  <div class="app">
    <Header/>
    <main ref="main">
      <router-view :key="router.fullPath"/>
    </main>
    <Footer/>
  </div>
</template>

<script setup>
import {inject, ref, onMounted} from "vue";
import {useRoute} from 'vue-router'
import {useStore} from 'vuex';
import Header from "./components/header/header.vue"
import Footer from "./components/footer/footer.vue"
import {getSiteParameterList} from "@/common/api";
import {ElMessage} from "element-plus";

let Common = inject('Common')
//console.log(Common,'common')
//console.log(process.env.VUE_APP_BASE_API)
let main = ref()

const store = useStore();
const router = useRoute();
//store.dispatch('user/getUserInfo');
//store.dispatch('nav/getNavList');
// const getAllData = async () =>{
  // await Promise.all([store.dispatch('nav/getNavList2'), store.dispatch('nav/getSwiper'), store.dispatch('nav/getHomeNavigatorList'), store.dispatch('nav/getNavList3')])
  // await store.dispatch('nav/getNavList2')
  // await store.dispatch('nav/getSwiper')
  // await store.dispatch('nav/getHomeNavigatorList')
  // await store.dispatch('nav/getNavList3')
// }
// getAllData();

// await Promise.all([store.dispatch('nav/getNavList2'), store.dispatch('nav/getSwiper'), store.dispatch('nav/getHomeNavigatorList'), store.dispatch('nav/getNavList3')])

store.dispatch('nav/getNavList2')
store.dispatch('nav/getSwiper')
store.dispatch('nav/getHomeNavigatorList')
store.dispatch('nav/getNavList3')

const getSiteParameter = () => {
  getSiteParameterList({
    siteID: Common.siteID,
  }, res => {
    let data = []
    if (res.data.header.code === 0) {
      let ParameterList = res.data.body.data
      //console.log(ParameterList,'站点参数')
      let companyInfo = {};
      ParameterList.forEach((item) => {
        // console.log(item)
        if (item.code == "webSiteCertificate") {
          companyInfo.webSiteCertificate = item
        }
        if (item.code == 'companyAddress') {
          companyInfo.companyAddress = item
        }
        if (item.code == "siteCompanyName") {
          companyInfo.siteCompanyName = item
        }
        if (item.code == "siteCompanyTel") {
          companyInfo.siteCompanyTel = item
        }
        if (item.code == "siteCompanyEmail") {
          companyInfo.siteCompanyEmail = item
        }
        if (item.code == "siteWeixinQrcodeUrl") {
          companyInfo.siteWeixinQrcodeUrl = item
        }
        if (item.code == "weixinLoginQcodePage") {
          companyInfo.weixinLoginQcodePage = item
        }
        if (item.code == 'supportCompanyEmail') {
          companyInfo.supportCompanyEmail = item
        }
        if (item.code == 'shopServiceMail') {
          companyInfo.shopServiceMail = item
        }
        if (item.code == 'scientificServiceEmail') {
          companyInfo.scientificServiceEmail = item
        }
      })
      localStorage.setItem('siteParams', JSON.stringify(companyInfo))
    } else {
      ElMessage({
        message: '请求失败',
        type: 'error',
      })
    }
  })
}

onMounted(() => {
  main.value.style.minHeight = `${document.documentElement.clientHeight - 473 - 80}px`
  window.addEventListener('resize', () => {
    main.value.style.minHeight = `${document.documentElement.clientHeight - 473 - 80}px`
  });
  // 首次加载时,初始化高度
  let screenWidth=0;
  let bannerHeight=0;
  screenWidth = window.innerWidth
  bannerHeight = 800 / 1920 * screenWidth
  Common.bannerHeight=bannerHeight
  console.log(Common.bannerHeight, 'Common.bannerHeight')
  // 窗口大小发生改变
  window.onresize = () => {
    screenWidth = window.innerWidth
    bannerHeight = 800 / 1920 * screenWidth
    Common.bannerHeight=bannerHeight
    console.log(Common.bannerHeight, '窗口改变')
  }
  getSiteParameter();
})

</script>

<style lang="scss">
#app,
html,
body,
.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

#app {
  padding-top: 80px;
}

.app {
  main {
    width: 100%;
  }
}
</style>
